<template>
  <ion-header ref="headerRef" class="ion-no-border">
    <app-grid fixed class="header-content">
      <app-row>
        <transition name="fade">
          <app-col
            v-if="layout.left.visible"
            :style="`padding-left: ${layout.left.paddingLeft}; padding-right:${layout.left.paddingRight};`"
            :size-4-xl="layout.left.size4XL"
            :size-3-xl="layout.left.size3XL"
            :size-2-xl="layout.left.size2XL"
            :size-xl="layout.left.sizeXL"
            :size-lg="layout.left.sizeLG"
            :size-md="layout.left.sizeMD"
            :size-sm="layout.left.sizeSM"
            :size-2-xs="layout.left.size2XS"
            :size-xs="layout.left.sizeXS"
            class="left"
          >
            <transition name="fade">
              <div
                v-if="is2XLWidth || is3XLWidth || is4XLWidth"
                v-tooltip.bottom="{
                  content: t('appMenu.home'),
                  theme: 'info-tooltip',
                  disabled: isAnyMobile,
                }"
                class="logo"
                @click="goToHomePage()"
              >
                <app-logo :with-color="true" />
              </div>
            </transition>

            <transition v-if="isQuickSearchEnabled" name="fade">
              <div v-if="isLGWidth" class="search">
                <ion-button mode="md" @click="openSearch()">
                  <!-- <ion-icon slot="start" :icon="icons.search" /> -->
                  <ion-label class="fw-400">
                    {{ t('search.quick') }}
                  </ion-label>
                </ion-button>
              </div>
            </transition>
          </app-col>
        </transition>
        <transition name="fade">
          <app-col
            v-if="layout.center.visible"
            :style="`padding-left: ${layout.center.paddingLeft}; padding-right:${layout.center.paddingRight};`"
            :size-4-xl="layout.center.size4XL"
            :size-3-xl="layout.center.size3XL"
            :size-2-xl="layout.center.size2XL"
            :size-xl="layout.center.sizeXL"
            :size-lg="layout.center.sizeLG"
            :size-md="layout.center.sizeMD"
            :size-sm="layout.center.sizeSM"
            :size-2-xs="layout.center.size2XS"
            :size-xs="layout.center.sizeXS"
            class="center"
          >
            <transition name="fade">
              <app-header-menu v-if="isLGWidth" />
              <app-network v-else :type="NetworkChangeEnum.Menu" />
            </transition>
          </app-col>
        </transition>
        <transition name="fade">
          <app-col
            v-if="layout.right.visible"
            :style="`padding-left: ${layout.right.paddingLeft}; padding-right:${layout.right.paddingRight};`"
            class="right"
            :size-4-xl="layout.right.size4XL"
            :size-3-xl="layout.right.size3XL"
            :size-2-xl="layout.right.size2XL"
            :size-xl="layout.right.sizeXL"
            :size-lg="layout.right.sizeLG"
            :size-md="layout.right.sizeMD"
            :size-sm="layout.right.sizeSM"
            :size-2-xs="layout.right.size2XS"
            :size-xs="layout.right.sizeXS"
          >
            <transition v-if="isQuickSearchEnabled" name="fade">
              <div v-if="!isLGWidth" class="only-icon-search" @click="openSearch()">
                <icons-provider
                  slot="start"
                  :icon-props="{
                    width: '24',
                    height: '24',
                    fill: 'var(--ion-color-custom)',
                  }"
                  :name="AppIconsEnum.SearchSm"
                />
              </div>
            </transition>

            <div v-if="isCampusEnabled" class="campus" @click="openCampusPopup()">
              <ion-badge mode="md" class="counter">{{ t('soon') }}</ion-badge>
              <div class="campus-icon">
                <icons-provider
                  :icon-props="{
                    width: '24',
                    height: '24',
                    fill: 'var(--ion-color-custom)',
                  }"
                  :name="AppIconsEnum.MonitorOutline"
                  strokewidth="1.8"
                />
              </div>
              <ion-label class="fw-400">{{ t('campus.title') }}</ion-label>
            </div>

            <div
              v-if="isDesktopOrTablet"
              :class="['notifications', { active: route.name === ROUTES_NAME.NOTIFICATIONS }]"
              :style="`margin-left: ${isLGWidth ? 'app-padding(lg)' : 0}`"
              @click="openNotifications()"
            >
              <transition name="fade">
                <ion-badge v-if="notificationsCount > 0" mode="md" class="counter">
                  {{ notificationsCount > 99 ? `${'99+'}` : notificationsCount }}
                </ion-badge>
              </transition>
              <div class="notifications-icon">
                <icons-provider
                  :icon-props="{
                    width: '29',
                    height: '29',
                    fill: 'var(--ion-color-custom)',
                  }"
                  :name="AppIconsEnum.Notifications"
                />
              </div>
              <ion-label class="fw-400">{{ t('appMenu.notifications') }}</ion-label>
            </div>

            <div
              v-tooltip.bottom="{
                content: currentUserFullName,
                theme: 'info-tooltip',
                disabled: isAnyMobile,
              }"
              :class="['user', { 'with-padding': !isSMWidth }]"
              @click="openRightMenu"
            >
              <user-avatar
                :with-online-status="true"
                :user-id="userStore.current?.id"
                :fio="currentUserFullName"
                :url="currentUserAvatar?.url ?? ''"
                :image="currentUserAvatar"
                :with-title="false"
                @on-avatar-click="openRightMenu"
              />
              <icons-provider
                slot="start"
                :icon-props="{
                  width: '24',
                  height: '24',
                }"
                :name="AppIconsEnum.ChevronDown"
              />
            </div>
          </app-col>
        </transition>
      </app-row>
    </app-grid>
  </ion-header>
</template>

<script lang="ts" setup>
import { IonHeader, IonLabel, IonBadge, IonButton } from '@ionic/vue';
import { useElementSize, useWindowFocus } from '@vueuse/core';
import { computed, ref, watch } from 'vue';
import type { ComputedRef, ComponentPublicInstance } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import { AppHeaderMenu, UserAvatar, AppLogo, AppNetwork, AppCol, AppGrid, AppRow, IconsProvider } from '@/components';
import { NetworkChangeEnum, AppIconsEnum } from '@/enums';
import { openAppRightPopover, openAppQuickSearchModal, isAnyMobile, isDesktopOrTablet, useMenu } from '@/helpers';
import { useI18n } from '@/i18n';
import { ROUTES_NAME } from '@/router';
import { useAppStore, useUserStore, useNotificationsStore } from '@/store';
import type { AppLayout, MediaModel } from '@/types';

//#region Variables
const appStore = useAppStore();
const userStore = useUserStore();
const notificationsStore = useNotificationsStore();

const router = useRouter();
const route = useRoute();

const { t } = useI18n();

const headerRef = ref<ComponentPublicInstance | null>(null);

const { height } = useElementSize(headerRef);
const focused = useWindowFocus();

const homePage: ComputedRef<any> = computed(() => appStore.homePage);
const isSMWidth: ComputedRef<boolean> = computed(() => appStore.isSMWidth);
const isXSWidth: ComputedRef<boolean> = computed(() => appStore.isXSWidth);
const isLGWidth: ComputedRef<boolean> = computed(() => appStore.isLGWidth);
const is2XLWidth: ComputedRef<boolean> = computed(() => appStore.is2XLWidth);
const is3XLWidth: ComputedRef<boolean> = computed(() => appStore.is3XLWidth);
const is4XLWidth: ComputedRef<boolean> = computed(() => appStore.is4XLWidth);

const currentUserFullName: ComputedRef<string> = computed(() => userStore.current?.fullName ?? '');
const currentUserAvatar: ComputedRef<MediaModel | null> = computed(() => userStore.current?.avatar ?? null);

const isCampusEnabled: ComputedRef<boolean> = computed(() => useMenu().isCampusEnabled() && isDesktopOrTablet);
const isQuickSearchEnabled: ComputedRef<boolean> = computed(() => useMenu().isQuickSearchEnabled());
const appGridPadding: ComputedRef<string> = computed(() => appStore.appGridPadding);
const notificationsCount: ComputedRef<number> = computed(() => notificationsStore.getUnreadNotificationsCount);
const layout: ComputedRef<AppLayout> = computed(() => {
  const showLeftColumn = isLGWidth.value;
  const showRightColumn = true;

  const data = {
    left: {
      visible: showLeftColumn,
      size4XL: '6',
      size3XL: '6',
      size2XL: '6',
      sizeXL: '6',
      sizeLG: '6',
      sizeMD: '0',
      sizeSM: '0',
      size2XS: '0',
      sizeXS: '0',
      paddingLeft: appGridPadding.value,
      paddingRight: `${+appGridPadding.value.slice(0, -2) / 2}px`,
    },
    center: {
      visible: true,
      size4XL: '15',
      size3XL: '15',
      size2XL: '15',
      sizeXL: '15',
      sizeLG: '15',
      sizeMD: '19',
      sizeSM: '15',
      size2XS: '15',
      sizeXS: '15',
      paddingLeft: showLeftColumn ? `${+appGridPadding.value.slice(0, -2) / 2}px` : 'unset',
      paddingRight: showRightColumn ? `${+appGridPadding.value.slice(0, -2) / 2}px` : 'unset',
    },
    right: {
      visible: showRightColumn,
      size4XL: '6',
      size3XL: '6',
      size2XL: '6',
      sizeXL: '6',
      sizeLG: '6',
      sizeMD: '8',
      sizeSM: '12',
      size2XS: '12',
      sizeXS: '12',
      paddingLeft: `${+appGridPadding.value.slice(0, -2) / 2}px`,
      paddingRight: appGridPadding.value,
    },
  };

  return data;
});
//#endregion

//#region Methods
const openRightMenu = async (ev: Event) => {
  await openAppRightPopover(ev);
};

const goToHomePage = async () => {
  if (homePage.value) {
    await router.push(homePage.value);
  }
};

const openNotifications = async () => {
  await router.push({ name: ROUTES_NAME.NOTIFICATIONS });
};

const openCampusPopup = async () => {
  await useMenu().openCampus();
};

const openSearch = async () => {
  await openAppQuickSearchModal(isXSWidth.value);
};
//#endregion

//#region Watchers
watch(focused, () => {
  appStore.$patch({ appHeaderHeight: height.value });
});

watch(height, () => {
  appStore.$patch({ appHeaderHeight: height.value });
});
//#endregion
</script>

<style scoped lang="scss">
ion-label {
  font-size: 0.9rem;
  white-space: nowrap;
}
ion-header {
  background: var(--ion-color-intra);
  min-height: calc(var(--ion-safe-area-top) + 58px);
  padding-top: var(--ion-safe-area-top);
  box-sizing: border-box;

  .app-grid.header-content {
    --ion-grid-columns: 27;
    height: 100%;

    .app-row {
      height: 100%;
    }

    .app-col {
      padding-bottom: 0;
      padding-top: 0;
    }

    .left {
      display: flex;
      align-items: center;

      .logo {
        height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: app-padding(lg);
        box-sizing: content-box;
        &::after {
          display: block;
          opacity: 0;
          content: '';
          width: 100%;
          height: 3px;
          background: var(--ion-color-custom);
          position: absolute;
          bottom: 0;
          border-radius: 1.5px;
          transition: all 0.15s ease-in-out;
        }
        &:hover {
          cursor: pointer;
          &::after {
            opacity: 1;
          }
        }
      }

      .search {
        display: flex;
        align-items: center;
        height: 100%;
        transition: transform 0.2s;
        width: 100%;
        overflow: hidden;
        ion-button {
          position: relative;
          @include resetStyleFromIonicButton;
          width: 100%;
          height: 42px;
          --background: color-mix(in srgb, rgba(var(--ion-color-intra-rgb), 0.1), var(--ion-color-custom) 10%);
          color: var(--ion-color-custom);
          --border-radius: #{app-radius(md)};
          --padding-end: #{app-padding(md)};
          --padding-start: #{app-padding(md)};
          overflow: hidden;
          ion-label {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 0.7rem;
          }
        }
      }
    }

    .right {
      display: flex;
      justify-content: end;
      width: 100%;
      .counter {
        top: 0.5rem;
        right: 50%;
        transform: translateX(100%);
        position: absolute;
        color: var(--ion-color-campus-badge);
        z-index: 1;
        font-size: 0.5rem;
        border-radius: 2rem;
        min-width: 1rem;
        height: 15.2px;
        --background: var(--ion-color-primary-contrast);
      }

      .user {
        height: 100%;
        max-width: 100%;
        display: flex;
        align-items: center;
        padding-left: app-padding(lg);
        transition: all 0.15s ease-in-out;
        user-select: none;

        ion-avatar {
          width: 40px;
          height: 40px;
          flex-shrink: 0;
        }
        ion-icon {
          padding-left: app-padding(md);
          flex-shrink: 0;
          color: var(--ion-color-custom);
        }
        &:hover {
          cursor: pointer;
          background: rgba(var(--ion-color-light-rgb), 0.1);
        }
        &-name {
          margin-left: app-padding(md);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: var(--ion-color-custom);
        }
        &.with-padding {
          padding-right: app-padding(md);
        }
      }

      .notifications {
        height: 100%;
        max-width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: 8px;
        padding-bottom: 4px;
        padding-inline: app-padding(lg);
        position: relative;
        transition: all 0.15s ease-in-out;
        color: var(--ion-color-custom);
        font-family: var(--ion-font-family);
        .counter {
          padding: 2px 5px;
          color: var(--ion-color-custom);
          font-size: 0.7rem;
          border-radius: 1rem;
          min-width: 1.3rem;
          --background: var(--ion-color-notification-badge);
        }
        &:hover {
          cursor: pointer;
          background: rgba(var(--ion-color-light-rgb), 0.1);
        }
        &.active {
          .notifications-icon {
            opacity: 1;
          }
        }
        &.active::after {
          display: block;
          content: '';
          width: 100%;
          height: 3px;
          background: var(--ion-color-custom);
          position: absolute;
          bottom: 0;
          left: 0;
          border-radius: 1.5px;
        }
      }

      .campus {
        height: 100%;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: var(--ion-color-custom);
        font-family: var(--ion-font-family);
        padding-top: 8px;
        padding-inline: app-padding(lg);
        position: relative;
        transition: all 0.15s ease-in-out;

        &:hover {
          cursor: pointer;
          background: rgba(var(--ion-color-light-rgb), 0.1);
        }

        &.active::after {
          display: block;
          content: '';
          width: 100%;
          height: 3px;
          background: var(--ion-color-custom);
          position: absolute;
          bottom: 0;
          left: 0;
          border-radius: 1.5px;
        }
      }

      .only-icon-search {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding-inline: app-padding(lg);
        &:hover {
          cursor: pointer;
          background: rgba(var(--ion-color-light-rgb), 0.1);
        }
        ion-icon {
          font-size: 1.5rem;
          color: var(--ion-color-custom);
        }
      }
    }
  }
}

@include fadeEnterTransition;
</style>
