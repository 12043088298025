export const enum FileStatusEnum {
  Init = 'init',
  Loading = 'loading',
  Success = 'success',
  Error = 'error',
  LargeFile = 'largeFile',
}

export enum RegistrationTypeEnum {
  ByCurrentDomain = 'byCurrentDomain',
  ByUri = 'byUri',
}

export const enum MessageActionEnum {
  Reply = 1,
  Edit = 2,
  Copy = 3,
  Forward = 4,
  DeleteForMe = 5,
  DeleteForAll = 6,
  Delete = 7,
}

export const enum MessageDeliveryStatusEnum {
  Draft = 'Draft',
  Error = 'Error',
  Delivered = 'Delivered',
  Read = 'Read',
  ReadAll = 'ReadAll',
  Deleted = 'Deleted',
}

export const enum MessageTypeEnum {
  Message = 'Message',
  CreateGroup = 'CreateGroup',
  AddParticipant = 'AddParticipient',
  RemoveParticipant = 'RemoveParticipient',
  RemoveYou = 'RemoveYou',
  YouNewAdmin = 'YouNewAdmin',
  SelfRemove = 'SelfRemove',
  FileVideo = 'FileVideo',
  FileImage = 'FileImage',
  FileDocument = 'FileDocument',
  Quote = 'Quote',
  Sticker = 'Sticker',
  Init = 'Init',
  Forward = 'Forward',
  ForwardOld = 'ForwardOld',
  InitVideoCall = 'InitVideoCall',
  EndVideoCall = 'EndVideoCall',
}

export const enum ChainTypeEnum {
  Active = 'Active',
  Archive = 'Archive',
}

export const enum ThemeAppEnum {
  System = 'System',
  Light = 'Light',
  Dark = 'Dark',
}

export const enum SendKeyEnum {
  Enter = 'Enter',
  CtrlEnter = 'CtrlEnter',
  CmdEnter = 'CmdEnter',
}

export const enum UserProfileTabEnum {
  Posts = 'posts',
  Additional = 'additional',
}

export const enum UserProfileAdditionalEnum {
  Feed = 'feed',
  AboutMe = 'aboutMe',
  ReView = 'review',
  Followers = 'followers',
  Following = 'following',
  Manager = 'manager',
  SubOrdinates = 'subordinates',
  Documents = 'documents',
}

export const enum MessengerChatTypeEnum {
  Chain = 'chain',
  User = 'user',
}

export const enum FeedEventAnswerEnum {
  Probably = 2,
  Yes = 1,
  No = 0,
  None = -1,
}

export const enum EventAttendingEnum {
  Yes = 'Attending',
  Probably = 'MaybeAttending',
  No = 'NotAttending',
}

export const enum GroupsAccessEnum {
  None = 0,
  SendRequest = 1,
  Member = 5,
  Admin = 10,
}

export const enum PostObserveViewersAccessEnum {
  None = 'None',
  User = 'User',
  Moderator = 'Moderator',
  Administrator = 'Administrator',
  SuperAdministrator = 'SuperAdministrator',
}

export const enum GroupsTypeEnum {
  PrivateVisible = 'PrivateVisible',
  PrivateHidden = 'PrivateHidden',
  Public = 'Public',
}

export const enum InterfaceSizeAppEnum {
  Small = 'small',
  Middle = 'middle',
  Large = 'large',
}

export const enum FeedTypeEnum {
  Text = 'Text',
  Announcement = 'Announcement',
  GroupCreated = 'GroupCreated',
  GroupDeleted = 'GroupDeleted',
  Badge = 'Badge',
  Event = 'Event',
  Poll = 'Poll',
  Idea = 'Idea',
  UserCreated = 'UserCreated',
  Task = 'Task',
}

export const enum FeedFilterTypeEnum {
  Recommended = 'Recommended',
  AllPublic = 'AllPublic',
  Announcement = 'Announcement',
  Following = 'Following',
  MyBookmarks = 'MyBookmarks',
  Planned = 'Planned',
  AllMy = 'AllMy',
  Polls = 'Polls',
  Ideas = 'Ideas',
  ByGroup = 'ByGroup',
  ByTag = 'ByTag',
  ByWiki = 'ByWiki',
  ByFile = 'ByFile',
  ByUser = 'ByUser',
  Search = 'Search',
}

export const enum FeedFlagEnum {
  UserProfile = 'UserProfile',
  GroupPage = 'GroupPage',
  FeedPage = 'FeedPage',
  PostPage = 'PostPage',
  CustomPage = 'CustomPage',
  EventModal = 'EventModal',
  SharedPost = 'SharedPost',
  SearchPage = 'SearchPage',
  IdeasPage = 'IdeasPage',
  ForceToReadModal = 'ForceToReadModal',
}

export const enum FeedTypeForButtonsEnum {
  Post = 'post',
  SharedMessage = 'shared',
  Comment = 'comment',
}

export const enum ValidationsEnum {
  Required = 'required',
  Email = 'email',
  Confirm = 'confirm',
  MinLength = 'minLength',
  LowerCase = 'lowerCase',
  UpperCase = 'upperCase',
  Numbers = 'numbers',
  Symbols = 'symbols',
  Unique = 'unique',
  NotSimple = 'notSimple',
}

export const enum UserPageInputLabelsEnum {
  FirstName = 'firstName',
  LastName = 'lastName',
  SubDivision = 'subDivision',
  Position = 'position',
  DateOfBirth = 'dateOfBirth',
  Email = 'email',
  PhoneMobile = 'phoneMobile',
  PhoneWork = 'phoneWork',
  AboutMe = 'aboutMe',
  Hobby = 'hobby',
  Education = 'education',
  WorkExperience = 'workExperience',
  Region = 'region',
  RoomLocation = 'roomLocation',
}

export const enum UserPagePhoneVisibleEnum {
  OnlyMe = 'onlyMe',
  All = 'all',
}

export const enum UserPageCategoriesEnum {
  Feed = 'feed',
  AboutMe = 'aboutMe',
  Followers = 'followers',
}

export const enum GroupPageTabEnum {
  Feed = 'feed',
  Participants = 'participants',
  Files = 'files',
  Administration = 'administration',
  Projects = 'projects',
  Dashboard = 'dashboard',
}

export const enum AppMenuEnum {
  /** @note Main block */
  Feed = 'feed',
  People = 'people',
  Groups = 'groups',
  Docs = 'docs',
  Notifications = 'notifications',
  Messenger = 'messenger',
  Pages = 'pages',
  HomePage = 'homePage',
  Calendar = 'calendar',
  Search = 'search',
  Topics = 'topics',
  UsageRules = 'usageRules',
  Projects = 'projects',
  ProjectsStatistics = 'projectsStatisctics',
  Tasks = 'tasks',
  Milestones = 'milestones',
  AiAssistant = 'aiAssistant',
  Profile = 'profile',
  Ideas = 'ideas',
  More = 'more',
  Login = 'login',
  Networks = 'networks',
  Settings = 'Settings',
  Wiki = 'wiki',
  CustomLink = 'customLink',
  SecondCustomLink = 'secondCustomLink',
  CustomPage = 'customPage',
  File = 'file',
  Plus = 'plus',
  Campus = 'campus',

  /** @note Admin block */
  Admin = 'admin',
  AdminDesign = 'adminDesign',
  AdminEmailFooter = 'adminEmailFooter',
  AdminNetworkSettings = 'adminNetworkSettings',
  AdminNetworkDomainList = 'adminNetworkDomainList',
  AdminBranding = 'adminBranding',
  AdminMobileApps = 'adminMobileApps',
  AdminUsageRules = 'adminUsageRules',
  AdminPasswordSettings = 'adminPasswordSettings',
  AdminApplications = 'adminApplications',
  AdminStatistics = 'adminStatistics',
  AdminBanner = 'adminBanner',
  AdminTags = 'adminTags',
  AdminUserManagement = 'adminUserManagement',
  AdminRestorePost = 'adminRestorePost',
  AdminBadges = 'adminBadges',

  /** @note Dev block */
  Icons = 'Icons',

  /* NOTE: Non-page components */
  LeftMenu = 'leftMenu',
}

export const enum AppMenuLanguagesEnum {
  DE = 'de',
  EN = 'en',
  CS = 'cs',
  ES = 'es',
  FR = 'fr',
  RU = 'ru',
}

export const enum AppLoadingTypeEnum {
  NetworkSetting = 'networkSetting',
  OtherLoading = 'otherLoading',
}

export const enum AppAlertTypeEnum {
  UpdateApp = 'updateApp',
  OtherAlert = 'otherAlert',
}

export const enum MessengerListActionEnum {
  Mute = 1,
  UnMute = 2,
  Archive = 3,
  UnArchive = 4,
  Info = 5,
  Delete = 6,
  Exit = 7,
}

export const enum CoverImageTypeEnum {
  UserCover = 'userCover',
  GroupCover = 'groupCover',
}

export const enum AvatarTypeEnum {
  UserAvatar = 'userAvatar',
  GroupAvatar = 'groupAvatar',
  ChatAvatar = 'chatAvatar',
  UserAvatarOnRegistration = 'UserAvatarOnRegistration',
  NetworkLogo = 'NetworkLogo',
  NetworkFavicon = 'NetworkFavicon',
}

export const enum AppImageChangeMenuEnum {
  Upload = 0,
  Delete = 1,
  Open = 2,
}

export const enum DocumentTypeEnum {
  Wiki = 'Wiki',
  Folder = 'Folder',
  File = 'File',
  FileVersion = 'FileVersion',
  ExternalLink = 'ExternalLink',
}

/**
 * @todo Change to const enum
 * To do so fix in src/helpers/useUsersHelper.ts - getUserRolesList (296)
 */
export enum UserRoleEnum {
  ExternalGroupUserReadLike = 11,
  ExternalGroupUserLikeUpsert = 12,
  ExternalGroupUser = 19,
  UserReadLike = 21,
  UserLikeUpsert = 22,
  User = 30,
  Expert = 31,
  BadgesManager = 32,
  InnovationManager = 33,
  LightModerator = 79,
  Moderator = 85,
  FreeExtNetAdministrator = 93,
  Administrator = 95,
  SuperAdministrator = 100,
  SecurityManager = 101,
  System = 110,
  TenantInstanceManager = 120,
  InstanceManager = 130,
}

export const enum IdeaTypeEnum {
  New = 'New',
  InProgress = 'InProgress',
  Realized = 'Realized',
  Archived = 'Archived',
  MostLiked = 'MostLiked',
  My = 'My',
}

export const enum FeedContextMenuActionEnum {
  SelectType = 'selectType',
  MarkAllAsRead = 'markAllAsRead',
  SelectFilter = 'selectFilter',
  Reset = 'reset',
}

export const enum WidgetFeedTypeEnum {
  AllPublic = 0,
  Recommended = 1,
  Following = 2,
  Polls = 4,
  MyBookmarks = 7,
  AllMy = 8,
  Ideas = 9,
  //AllPublicBadges =3,
  //AllPublicFiles = 5,
  //AllPublicWikis = 6,
  //AllCompany = ?,
  //AllPublicQuestions=?,
  //AllPublicEvents=?,
  //Incoming=?,
  //MyLikes=?,

  //Локальные варианты
  CustomGroup = 'CustomGroup',
  CustomTag = 'CustomTag',
}

export const enum PostMenuActionEnum {
  Open = 1,
  Delete = 2,
  Edit = 3,
  AddToBookmarks = 4,
  RemoveFromBookmarks = 5,
  Follow = 6,
  UnFollow = 7,
  Complain = 8,
  SendToEmail = 9,
  SendToExternalEmail = 10,
  Pin = 11,
  UnPin = 12,
  CopyText = 13,
  Share = 14,
  SendNow = 15,
  ReSchedule = 16,
  ShowViewers = 17,
  SendAsArchiveToEmail = 18,
  DownloadAsPDF = 19,
  DownloadAsZIP = 20,
  Download = 21,
  Send = 22,
  Repost = 23,
  ShareGeneral = 24,
}

export const enum PostUploadFileEnum {
  UploadFromDevice = 1,
  UploadFromGroup = 2,
  CreateDocument = 3,
  CreateWiki = 4,
}

export const enum CommentActionEnum {
  Reply = 1,
  Delete = 2,
  CopyText = 3,
}

export const enum PostTextActionEnum {
  CopyText = 1,
}

export const enum PlannedPostActionEnum {
  SchedulePost = 1,
}

export const enum PlannedPostStatusEnum {
  Planned = 1,
}

export const enum PostTypeActionEnum {
  Text = 'text',
  Event = 'event',
  CalendarEvent = 'calendarEvent',
  Announcement = 'announcement',
  Poll = 'poll',
  Idea = 'idea',
  Task = 'task',
  Badge = 'badge',
  Comment = 'comment',
  TaskComment = 'taskComment',
  EditText = 'editText',
  EditEvent = 'editEvent',
  EditAnnouncement = 'editAnnouncement',
  EditPoll = 'editPoll',
  EditIdea = 'editIdea',
  EditBadge = 'editBadge',
}

export const enum PostComplainTypeEnum {
  RulesViolation = 1,
  SpamAndExperienceFarming = 2,
}

export const enum FileActionEnum {
  Open = 1,
  Download = 2,
  Move = 3,
  Rename = 4,
  Delete = 5,
  Share = 6,
  NewVersion = 7,
  OpenInNewWindow = 8,
  SendAsArchiveToEmail = 9,
  Edit = 10,
  ShowHistory = 11,
  Restore = 12,
  GoToCurrentVersion = 13,
  ShowRelations = 14,
  MarkOfficial = 15,
  Follow = 16,
  Unfollow = 17,
  ShowFollowers = 18,
}

export const enum DocMenuOptionsFlagEnum {
  All = 'all',
  Share = 'share',
}

export const enum DocShareOptionsEnum {
  Share = 'shareAndRepost',
  SendAsArchiveToEmail = 'sendAsArchiveToEmail',
}

export const enum DocsMenuActionEnum {
  UploadFile = 'uploadFile',
  UploadFolder = 'uploadFolder',
  CreateFile = 'createFile',
  CreateFolder = 'createFolder',
  CreateWiki = 'create',
}

export enum DocBrowserModeEnum {
  All = 'All',
  Recent = 'Recent',
  /** @deprecated */ Uploaded = 'Uploaded',
  Groups = 'Groups',
  Follow = 'Follow',
}

/** Use to indicate the entity in which documents are currently provided
 *  Depending on the settings passed, we decide which elements to show and which to hide
 */
export enum DocBrowserEntityEnum {
  /** Use in global search page */
  SearchPage = 'SearchPage',

  /** Use in docs page */
  DocsPage = 'DocsPage',

  /** Use for group docs */
  GroupPage = 'GroupPage',

  /** Use for attachments modal */
  AttachmentModal = 'AttachmentModal',

  /** Use for folder modal */
  FolderModal = 'FolderModal',
}

export enum DocBrowserFilterActionEnum {
  SelectGroup = 'SelectGroup',
  SelectUser = 'SelectUser',
  ResetFilter = 'ResetFilter',
  EnableSearchEverywhere = 'EnableSearchEverywhere',
}

export const enum ChatModalEnum {
  Home = 'home',
  Participants = 'participants',
  Administrators = 'administrators',
  AddParticipants = 'addParticipants',
  AddAdministrators = 'addAdministrators',
}

export const enum ProfileEditEnum {
  None = 'None',
  Administrators = 'Administrators',
  All = 'All',
}

export const enum AllowMobileAppEnum {
  Off = 'Off',
  AdminOrHigher = 'AdminOrHigher',
  All = 'All',
}

export const enum DataViewMode {
  List = 'List',
  Grid = 'Grid',
  Table = 'Table',
}

export const enum GroupsFilterEnum {
  All = 'all',
  ByUser = 'byUser',
  CanPost = 'canPost',
  MyGroups = 'myGroups',
  Admin = 'admin',
  Suggestion = 'suggestion',
  Hidden = 'hidden',
  AvailableProjects = 'availableProjects',
}

export const enum UsersFilterEnum {
  UsersPage = 'usersPage',
  List = 'list',
  ByGroup = 'byGroup',
  Followers = 'followers',
  ChosenMentions = 'chosenMentions',
  ChosenOthers = 'chosenOthers',
  Search = 'search',
  Following = 'following',
  AllForAdmin = 'allForAdmin',
  ByBadge = 'byBadge',
}

export const enum PagesFilterEnum {
  All = 'all',
  Search = 'search',
}

export const enum PostsModeEnum {
  Feed = 'Feed',
  ByGroup = 'ByGroup',
  ByUser = 'ByUser',
  Idea = 'Idea',
}

export const enum PostsFilterEnum {
  All = 'All',
  Read = 'Read',
  Unread = 'Unread',
}

export const enum MeetStatusEnum {
  Accept = 0,
  Reject = 1,
  Timeout = 2,
  BusyOnAnotherLine = 3,
  Connecting = 4,
}

export const enum CustomPageWidgetsEnum {
  WidgetHtml = 'WidgetHtml',
  WidgetSingleUserItem = 'WidgetSingleUserItem',
  WidgetPoll = 'WidgetPoll',
  WidgetBanner = 'WidgetBanner',
  WidgetPeople = 'WidgetPeople',
  WidgetTextBlock = 'WidgetTextBlock',
  WidgetFilesWikies = 'WidgetFilesWikies',
  WidgetGroups = 'WidgetGroups',
  WidgetBirthdays = 'WidgetBirthdays',
  WidgetFeed = 'WidgetFeed',
  WidgetCalendar = 'WidgetCalendar',
  WidgetInvite = 'WidgetInvite',
  WidgetGroupMembers = 'WidgetGroupMembers',
  WidgetGroupInformation = 'WidgetGroupInformation',
  WidgetGroupAdmins = 'WidgetGroupAdmins',
  WidgetSlider = 'WidgetSlider',
  WidgetPublisher = 'WidgetPublisher',
}

export const enum CustomPageWidgetsPositionEnum {
  LeftSidebarWidgets = 'leftSidebarWidgets',
  RightSidebarWidgets = 'rightSidebarWidgets',
  MainWidgets = 'mainWidgets',
}

export const enum HomePageEnum {
  Group = 'Group',
  CustomPage = 'CustomPage',
  DefaultNewsFeed = 'DefaultNewsFeed',
  Tag = 'Tag',
}

export const enum GroupInvitationStatusEnum {
  Join = 'Join',
  JoinedInGroup = 'JoinedInGroup',
  ReceiveInvitation = 'ReceiveInvitation',
  SendInvite = 'SendInvite',
  SendError = 'SendError',
  NotAllowed = 'NotAllowed',
}

export const enum GroupInviteModeEnum {
  None = 0,
  AddById = 1,
  AddByEmail = 2,
  AddExternal = 3,
}

export const enum AllowExternalUsersToGroupEnum {
  Off = 'Off',
  NetworkAdmins = 'NetworkAdmins',
  GroupAdmins = 'GroupAdmins',
  All = 'All',
}

export const enum GroupAdministrationOptionsEnum {
  ShowMembers = 0,
  ShowInformation = 1,
  ShowDocs = 2,
  EnableLikes = 3,
  EnableComments = 4,
  EnableShared = 5,
  EnableTaskManager = 6,
  EnableNotificationsOnJoin = 7,
}

export const enum ActionAccessEnum {
  NewVersion = 0,
  Rename = 1,
  Move = 2,
  Share = 3,
  Preview = 4,
  Delete = 5,
  Edit = 6,
  Like = 7,
  Comment = 8,
  Pin = 9,
  //? Who is 10
  ObserveViewers = 11,
  MarkAsOfficial = 12,
  ShareAsArchive = 13,
}

export const enum NetworkChangeEnum {
  Messenger = 'messenger',
  Feed = 'feed',
  Menu = 'menu',
}

export const enum DefaultUserItemTypesEnum {
  Text = 4,
  Badge = 5,
  Poll = 6,
  Event = 7,
  Idea = 8,
  Announcement = 9,
  Task = 10,
}

export const enum EventCalendarSourceEnum {
  All = 1,
  My = 2,
  Groups = 3,
}

export const enum EventCalendarPeriodEnum {
  Month = 1,
  Period = 2,
}

export const enum WidgetCalendarPeriodEnum {
  Month = 1,
  Week = 2,
}

export const enum CalendarTypeEnum {
  CalendarPage = 'calendarPage',
  CustomPage = 'customPage',
}

export const enum CalendarViewModeEnum {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

export const enum CalendarCellActionEnum {
  CreateEvent = 1,
  ShowEvents = 2,
  GoInside = 3,
  GoOutside = 4,
}

export const enum AppImageType {
  NetworkLogo = 0,
  ChatAvatar = 1,
}

export const enum ImageViewerMenuEnum {
  Download = 0,
  Share = 1,
  Delete = 2,
}

export const enum ForwardMessageTypeEnum {
  ToChain = 0,
  ToUser = 1,
}

export const enum PermissionTypes {
  Camera = 'camera',
  Files = 'files',
  Audio = 'audio',
}

export const enum TopicsFilterEnum {
  Popular = 'Popular',
  Recommended = 'Recommended',
  Following = 'Following',
  Search = 'TopicsSearch',
  SearchPage = 'SearchPage',
}

export const enum TopicsSortTypeEnum {
  ByPopularity = 'ByPopularity',
  ByTitle = 'byTitle',
}

export const enum CallsEnum {
  Phone = 'phone',
  Mobile = 'mobile',
  Work = 'work',
  Application = 'application',
}

export const enum SearchType {
  Posts = 'userItem',
  Users = 'people',
  Groups = 'group',
  Tags = 'tag',
  Wikis = 'wiki',
  Files = 'file',
  Pages = 'page',
}

export const enum SearchUserItemTypes {
  Text = '4',
  Badge = '5',
  Poll = '6',
  PublisherEvent = '7',
  Idea = '8',
  Announcement = '9',
}

export const enum MessageDirectionTypeEnum {
  Outgoing = 'Outgoing',
  Incoming = 'Incoming',
}

export const enum SavedContactResponseEnum {
  Saved = 'saved',
  Edited = 'edited',
  Exists = 'exists',
  Error = 'error',
}

export const enum MessageDeleteEnum {
  ForAll = 'forAll',
  ForMe = 'forMe',
}

export const enum PagesBackgroundEnum {
  Default = 'default',
  CustomImage = 'customImage',
}

export const enum PageToPostCreateEnum {
  Feed = 'feed',
  Group = 'group',
  Tag = 'tag',
  Ideas = 'ideas',
  CustomPage = 'customPage',
  GroupDashboard = 'groupDashboard',
}

export const enum IdeaStatusEnum {
  New = 'New',
  InProgress = 'InProgress',
  Realized = 'Realized',
  Archived = 'Archived',
}

export const enum OfficeDocumentCreateEnum {
  Text = 'text',
  Table = 'table',
  Presentation = 'presentation',
}

export const enum TaskManagementColumnType {
  SingleTag = 'SingleTag',
  Opened = 'Opened',
  Closed = 'Closed',
  Plug = 'Plug',
  New = 'New',
}

export const enum TaskManagementViewModeEnum {
  Board = 'board',
  List = 'list',
  Table = 'table',
}

export const enum TaskManagementTaskModalMenuCategoryEnum {
  Features = 0,
  Actions = 1,
}

export enum TaskManagementTaskModalMenuItemActionEnum {
  Assignee = 0,
  Tag = 1,
  Date = 2,
  Attachments = 3,
  Milestone = 4,
  Move = 5,
  Copy = 6,
  Close = 7,
  Archive = 8,
  Share = 9,
  Delete = 10,
  Participants = 11,
  RemoveAssignee = 12,
  Notifications = 13,
}

export const enum TaskManagementColumnActionEnum {
  Delete = 0,
  CreateNewTask = 1,
}

export const enum TaskManagementMilestonesViewModeEnum {
  Closed = 'closed',
  Opened = 'opened',
}

export const enum TaskManagementTasksStatusFilterEnum {
  All,
  Open,
  Closed,
}

export const enum TaskManagementTasksPageTypeEnum {
  Author = 'author',
  Assignee = 'assignee',
}

export const enum TaskManagementMainHeaderMenuEnum {
  SwitchProjects = 0,
  SwitchViewMode = 1,
  Expand = 2,
  CreateColumn = 3,
  CreateTask = 4,
  EditBoard = 5,
  SwitchMilestonesViewMode = 6,
  CreateMilestone = 7,
  OpenSortingMenu = 8,
  SetSort = 9,
  Refresh = 10,
  ChangeStatusFilter = 11,
}

export const enum TaskManagementMainHeaderSearchModesEnum {
  Name = 'name',
  Assigne = 'assigne',
  Author = 'author',
  Tag = 'tag',
  Milestone = 'milestone',
  Status = 'status',
}

export const enum TaskManagementActiveSortTypeEnum {
  Milestones = 'milestones',
  Projects = 'projects',
  Tasks = 'tasks',
}

export const enum TaskManagementTasksSortByEnum {
  Identifier = 'Identifier',
  Title = 'Title',
  DateCreated = 'DateCreated',
  DateLastUpdated = 'DateLastUpdated',
  DateStart = 'DateStart',
  DateDue = 'DateDue',
  MilestoneDateDue = 'MilestoneDateDue',
}

export const enum TaskManagementMilestonesSortByEnum {
  Title = 'Title',
  DateStart = 'DateStart',
  DateDue = 'DateDue',
  DateCreated = 'DateCreated',
}

export const enum TaskManagementProjectsSortByEnum {
  Title = 'Title',
  DateCreated = 'DateCreated',
  DateDue = 'DateDue',
  GroupTitle = 'GroupTitle',
}

export const enum TaskManagementSortDirectionEnum {
  Asc = 'Asc',
  Desc = 'Desc',
}

export const enum TaskManagementTaskHistoryActionType {
  Created = 'Created',
  Closed = 'Closed',
  Reopened = 'Reopened',
  Archived = 'Archived',
  Dearchived = 'Dearchived',
  TitleUpdated = 'TitleUpdated',
  DescriptionUpdated = 'DescriptionUpdated',
  AssigneeChanged = 'AssigneeChanged',
  MilestoneChanged = 'MilestoneChanged',
  DateStartUpdated = 'DateStartUpdated',
  DateDueUpdated = 'DateDueUpdated',
  TagsAdded = 'TagsAdded',
  TagsRemoved = 'TagsRemoved',
  FilesAdded = 'FilesAdded',
  FilesRemoved = 'FilesRemoved',
  WikiesAdded = 'WikiesAdded',
  WikiesRemoved = 'WikiesRemoved',
  LinksAdded = 'LinksAdded',
  LinksRemoved = 'LinksRemoved',
  ParticipantsAdded = 'ParticipantsAdded',
  ParticipantsRemoved = 'ParticipantsRemoved',
  CommentCreated = 'CommentCreated',
  CommentUpdated = 'CommentUpdated',
  CommentDeleted = 'CommentDeleted',
  ReporterChanged = 'ReporterChanged',
}

export const enum AppInfoListEnum {
  Version = 'version',
  Build = 'build',
  CommitHash = 'commitHash',
  CommitTime = 'commitTime',
  Core = 'core',
  Company = 'company',
  SignalR = 'signalR',
  Api = 'api',
  Vue = 'vue',
  Ionic = 'ionic',
  Capacitor = 'capacitor',
}

export const enum TaskManagementAccessLevelEnum {
  None = 'None',
  User = 'User',
  Administrator = 'Administrator',
}

export const enum CustomPageInfoEditActionsEnum {
  None = 0,
  EditTitle = 1,
  EditMainAlias = 2,
  ShowAuthor = 3,
  ShowModifier = 4,
  AccessEdit = 5,
}

export const enum CustomPageInfoEditControlsEnum {
  AccessEdit = 0,
  Delete = 1,
  ToDraft = 2,
  Publish = 3,
  Cancel = 4,
}

export const enum CustomPageShowTypeEnum {
  NetworkUsersOnly = 0,
  ExternalUsersOnly = 1,
  AllRegisteredUsers = 2,
  Public = 3,
}

export const enum AiAssistantAccessLevelEnum {
  None = 'None',
  User = 'User',
  Administrator = 'Administrator',
}

export const enum WidgetResourcesTypeItems {
  File = 'File',
  Wiki = 'Wiki',
  UserGroup = 'UserGroup',
  ExternalLink = 'ExternalLink',
  User = 'User',
  Folder = 'Folder',
}

export const enum AppFabButtonEnum {
  CreatePost = 'CreatePost',
  NewChat = 'NewChat',
  ScrollToBottom = 'ScrollToBottom',
  CreateEvent = 'CreateEvent',
  CreatePage = 'CreatePage',
  PageInfo = 'PageInfo',
  EditPage = 'EditPage',
  DashboardInfo = 'DashboardInfo',
  EditDashboard = 'EditDashboard',
  CreateMilestone = 'CreateMilestone',
  CreateProject = 'CreateProject',
  CreateTask = 'CreateTask',
  OpenAdminMenu = 'OpenAdminMenu',
}

export const enum SectionTypeEnum {
  Favorites = 'Favorites',
  Helpful = 'Helpful',
  Groups = 'Groups',
}

export const enum RegistrationStepsEnum {
  Domain = 'Domain',
  Email = 'Email',
  UsageRules = 'UsageRules',
  UserInfo = 'UserInfo',
  Phone = 'Phone',
  Photo = 'Photo',
  Invite = 'Invite',
  Follow = 'Follow',
  Groups = 'Groups',
  Finish = 'Finish',
}

export const enum RegistrationActionEnum {
  NextStep = 'NextStep', // Cледующий шаг
  PreviousStep = 'PreviousStep', // Предыдущий шаг
  Skip = 'Skip', // Пропустить шаг
  Locate = 'Locate', // Определить сеть
  SendCodeToEmail = 'SendCodeToEmail', // Отправить код на email
  ActivateRegistration = 'ActivateRegistration', // Активировать регистрацию
  SendCodeToPhone = 'SendCodeToPhone', // Отправить код на телефон
  ConfirmPhoneCode = 'ConfirmPhoneCode', // Подтвердить код с телефона
  UploadAvatar = 'UploadAvatar', // Загрузить аватар
  Registration = 'Registration', // Отправить основную форму и выполнить регистрацию
  FollowToUsers = 'FollowToUsers', // Подписаться на юзеров
  JoinToGroups = 'JoinToGroups', // Подать заявку на вступление в группу
  InviteUser = 'inviteUser', // Пригласить пользователя по email
  CompleteRegistration = 'CompleteRegistration', // Завершить регистрацию
}

export const enum RegistrationActivateStatusEnum {
  UserIsExits = 'UserIsExits', // пользователь существует в текущей сети
  UserByForms = 'UserByForms', //домашний пользователь не найден
  UserInNetwork = 'UserInNetwork', //домашний пользователь существует - нужно авторизоваться
  UserByNetwork = 'UserByNetwork', //найдена домашняя сеть - регистрируем в ней
  UserByParameters = 'UserByParameters', //регистрация по спец параметрам
}

export const enum CountriesEnum {
  Armenia = 'Armenia',
  Austria = 'Austria',
  Belarus = 'Belarus',
  Belgium = 'Belgium',
  Canada = 'Canada',
  Czech = 'Czech',
  Germany = 'Germany',
  Kazakhstan = 'Kazakhstan',
  Russia = 'Russia',
  Uk = 'Uk',
  Usa = 'Usa',
  Italy = 'Italy',
  Spain = 'Spain',
}

export const enum CountryCodesEnum {
  Armenia = '+374',
  Austria = '+43',
  Belarus = '+375',
  Belgium = '+32',
  Canada = '+1',
  Czech = '+420',
  Uk = '+44',
  Germany = '+49',
  Kazakhstan = '+7',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  Russia = '+7',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  Usa = '+1',
  Italy = '+39',
  Spain = '+34',
}

export const enum BreakpointsEnum {
  None = '',
  XS = 'Xs',
  '2XS' = '2Xs',
  SM = 'Sm',
  MD = 'Md',
  LG = 'Lg',
  XL = 'Xl',
  '2XL' = '2Xl',
  '3XL' = '3Xl',
  '4XL' = '4Xl',
}

export const enum ModalWidthsEnum {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  FULL = 'full',
}

export const enum ModalHeightsEnum {
  XS = 'xs',
  '2XS' = '2xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  AUTO = 'auto',
  FULL = 'full',
  AUTO_FULL = 'auto-full',
}

export const enum AppBarEnum {
  Refresh = 'Refresh',
  Search = 'Search',
  ViewMode = 'ViewMode',
  SortingType = 'SortingType',
  SortingDirection = 'SortingDirection',
  FilterType = 'FilterType',
  Create = 'Create',
  Menu = 'Menu',
  DocsFilter = 'DocsFilter',
}

export const enum AppCardsActionEnum {
  None,
  GroupJoin,
  GroupLeave,
  GroupSendRequest,
  UserFollow,
  UserUnfollow,
  Open,
  ChangeNetwork,
  OpenRequested,
}

export const enum UploadFileTypes {
  //NOTE: One any file
  SingleAnyFile = 0,
  //NOTE: A lot of different files
  ManyDifferentFiles = 1,
  //NOTE: Just one picture
  SingleImage = 2,
  //NOTE: Pictures only
  ManyImages = 3,
  //NOTE: Only one video
  SingleVideo = 4,
  //NOTE: Video only
  ManyVideos = 5,
  //NOTE: Ai supported files
  AiSupportedFiles = 6,
  //NOTE: Ai supported images
  AiSupportedImages = 7,
}

export const enum ShareEntityType {
  File = 'File',
  Wiki = 'Wiki',
  Post = 'Post',
}

export const enum ShareArchiveLinkType {
  UserItem = 'UserItem',
  File = 'File',
  Folder = 'Folder',
  Wiki = 'Wiki',
}

export const enum UserAdminAccessLevel {
  None = 'None',
  All = 'All',
  Administrator = 'Administrator',
}

export const enum BadgeDisplayType {
  Post,
  Profile,
  UserName,
  ListItem,
}

export const enum CallStartStatusEnum {
  Start = 100,
  Connect = 200,
}

export const enum SelfInviteAllowedEnum {
  BlackListEmail = -1,
  WhiteListEmail = 0,
  RegisterInHomeNetwork = 1,
}

export enum UserRegisterStatusEnum {
  CompanyNotExisting = 'CompanyNotExisting',
  EmptyFirstOrLastName = 'EmptyFirstOrLastName',
  Error = 'Error',
  AlreadyRegistered = 'AlreadyRegistered',
  ExistingUser = 'ExistingUser',
  EmailNotAllowedForRegistration = 'EmailNotAllowedForRegistration',
  Ok = 'Ok',
  Allow = 'Allow',
  InBlackList = 'InBlackList',
  RegistrationRequestSent = 'RegistrationRequestSent',
  BadEmailSuffix = 'BadEmailSuffix',
}

export const enum AiModeEnum {
  Default,
  ImageVariation,
  ImageEditing,
  ImageGeneration,
  // ImageRecognition,
}

export const enum DocumentExtensionEnum {
  PDF = 'pdf',
  ZIP = 'zip',
  DOCX = 'docx',
}

export const enum SettingsTypeEnum {
  Theme,
  Background,
  Sound,
  Search,
  Sending,
  Lang,
  Cache,
  NetworkRules,
  AboutApp,
  InterfaceSize,
  Notifications,
  PostsOnBehalf,
  AddToGroup,
  DocsInNewWindow,
}

export const enum SettingsActionEnum {
  None,
  ClearCache,
  OpenUsageRules,
  Support,
  OpenNotifications,
}

export const enum RequirementsEnum {
  All,
  UsageRules,
  Password,
  ForceToRead,
}

export const enum DigestSubscribeEnum {
  Never = 'Never',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Manual = 'Manual',
}

export const enum RegistrationModeEnum {
  Domain = 'Domain',
  Sync = 'Sync',
  UserParameters = 'UserParameters',
  Oauth = 'Oauth',
  All = 'All',
}

export const enum OrgChartTypeEnum {
  Off = 'Off',
  Social = 'Social',
  ActiveDirectory = 'ActiveDirectory',
}

export const enum UserGroupRoleEnum {
  StandardUser = 'User',
  CanLikeAndComment = 'UserLikeUpsert',
  CanOnlyReadAndLike = 'UserReadLike',
  ExternalGroupUser = 'ExternalGroupUser',
  ExternalUserLikeAndComment = 'ExternalGroupUserLikeUpsert',
  ExternalUserReadAndLike = 'ExternalGroupUserReadLike',
}

export const enum AccessByRoleEnum {
  Off = 'Off',
  AllUsers = 'AllUsers',
  ModeratorOrHigher = 'ModeratorOrHigher',
  AdminOrHigher = 'AdminOrHigher',
  SuperAdminOrHigher = 'SuperAdminOrHigher',
}

export const enum UserItemLinkExpirationEnum {
  Never = 'Never',
  OneHour = 'OneHour',
  TwelveHours = 'TwelveHours',
  OneDay = 'OneDay',
  OneWeek = 'OneWeek',
  TwoWeeks = 'TwoWeeks',
  FourWeeks = 'FourWeeks',
}

export const enum ColorPickerDisplayType {
  PaletteSelect,
  AdvancedColorSelect,
}

export const enum DownloadPostAccessLevel {
  None = 'None',
  All = 'All',
  Administrator = 'Administrator',
}

export const enum AdminUserManagementMenuEnum {
  OpenProfile = 'OpenProfile',
  EditRole = 'EditRole',
  RestorePassword = 'RestorePassword',
  SetPassword = 'SetPassword',
  EditAccess = 'EditAccess',
}

export const enum UserAccessEnum {
  None,
  Activate,
  Deactivate,
  Block,
  Unblock,
  Delete,
  DeleteAnonim,
}

export const enum ActivityTypeEnum {
  Never = 'Never',
  Daily = 'Daily',
  Weekly = 'Weekly',
}

export const enum UserStatusEnum {
  Normal,
  Banned,
  Deleted,
}

export const enum DeletionUserKindEnum {
  Basic,
  Anonymize,
  WipeData,
}

export const enum AutoUpdateStatusEnum {
  Done = 0,
  Processing = 1,
  Fail = 2,
}

export const enum EventBusEnum {
  ResetAppBarSearch = 'ResetAppBarSearch',
  PublisherDropZone = 'PublisherDropZone',
  DocBrowserSetMode = 'DocBrowserSetMode',
  DocBrowserChangeFilter = 'DocBrowserChangeFilter',
  LoadUsers = 'LoadUsers',
}

export const enum ChangeTypeEnum {
  RollBack = 0,
  Publish = 1,
}

export const enum NetworkSettingsTypeEnum {
  Network = 'network',
  Invitation = 'invitation',
  Users = 'users',
  Registration = 'registration',
  NetworkType = 'networkType',
  MobileApps = 'mobileApps',
  Branding = 'branding',
  Style = 'style', //?
}

export const enum EditModeEnum {
  Create = 'create',
  Edit = 'edit',
}

export const enum HeadBackgroundTypeEnum {
  ExampleColors = 'ExampleColors',
  CustomColors = 'CustomColors',
  CustomHeadImage = 'CustomHeadImage',
}

export const enum CompanyEnum {
  SHGT = 'SHGT',
  CAMPUS = 'CAMPUS',
  ANDERSEN = 'ANDERSEN',
  VOEB = 'VOEB',
  BKG = 'BKG',
  DLT = 'DLT',
  AWO = 'AWO',
  OSTWERFT = 'OSTWERFT',
  BAYKOMMUN = 'BAYKOMMUN',
  BWV = 'BWV',
  INTRANETLOHR = 'INTRANETLOHR',
  BLAUESKREUZ = 'BLAUESKREUZ',
  BEHORDENSPIEGEL = 'BEHORDENSPIEGEL',
  VGoD = 'VGoD',
  KLINIKUM_ERDING = 'KLINIKUM-ERDING',
  BIRZER_NEUMANN = 'BIRZER_NEUMANN',

  /** @note Saarland Secondary Networks */
  MASFG = 'MASFG',
  LKTSAAR = 'LKTSAAR',
  MZG = 'MZG',
  NK = 'NK',
  RVSBR = 'RVSBR',
  SLS = 'SLS',
  SPK = 'SPK',
  WND = 'WND',
}

export const enum UIKitFilterEnum {
  Intra = 'Intra',
  Gitlab = 'Gitlab',
}

/**
 * @todo Change to const enum
 * To do so fix in src/components/Docs/DocsItems/DocsItemView.vue - icons (324) + nameForIconsProvider (370)
 */
export enum AppIconsEnum {
  // Default
  None = 'none',

  // Country Flags
  Armenia = 'armenia',
  Austria = 'austria',
  Belarus = 'belarus',
  Belgium = 'belgium',
  Canada = 'canada',
  Czech = 'czech',
  England = 'england',
  Germany = 'germany',
  Kazakhstan = 'kazakhstan',
  Russia = 'russia',
  UK = 'uk',
  USA = 'usa',

  // File Extensions
  Avif = 'avif',
  Bmp = 'bmp',
  Csv = 'csv',
  Doc = 'docx',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  Docx = 'docx',
  Gif = 'gif',
  Html = 'html',
  Jpeg = 'jpeg',
  Jpg = 'jpg',
  Markdown = 'markdown',
  Json = 'json',
  Mov = 'mov',
  Mp4 = 'mp4',
  Pbm = 'pbm',
  Pdf = 'pdf',
  Png = 'png',
  Ppt = 'pptx',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  Pptx = 'pptx',
  Rtf = 'rtf',
  Svg = 'svg',
  Tga = 'tga',
  Tiff = 'tiff',
  Txt = 'txt',
  Webp = 'webp',
  Wiki = 'wiki',
  Xls = 'xlsx',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  Xlsx = 'xlsx',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  Xlsm = 'xlsx',
  Zip = 'zip',

  CheckDouble = 'check-double',
  CircleAnim = 'circle-anim',
  ClearData = 'clear-data',
  Headset = 'headset',
  Login = 'login',
  MenuDots = 'menu-dots',
  MenuDotsVertical = 'menu-dots-vertical',
  MicrophoneOff = 'microphone-off',
  Microphone = 'microphone',
  PhoneCallEnd = 'phone-call-end',
  PhoneCall = 'phone-call',
  Phone = 'phone',
  TextSize = 'text-size',
  UserCheck = 'user-check',
  UserDeleteCross = 'user-delete-cross',
  UserTime = 'user-time',
  UsersAdd = 'users-add',
  UsersCheck = 'users-check',
  UsersRemove = 'users-remove',
  VideoOff = 'video-off',

  // Other
  Add = 'add',
  Admin = 'admin',
  AlphaOrderAz = 'alpha-order-az',
  AlphaOrderZa = 'alpha-order-za',
  Appearance = 'appearance',
  Applications = 'applications',
  ArchiveLink = 'archive-link',
  ArrowSmRight = 'arrow-sm-right',
  ArrowSmDown = 'arrow-sm-down',
  ArrowDown = 'arrow-down',
  ArrowLeft = 'arrow-left',
  ArrowRight = 'arrow-right',
  ArrowUp = 'arrow-up',
  Bookmark = 'bookmark',
  Bulb = 'bulb',
  Bullhorn = 'bullhorn',
  Calendar = 'calendar',
  CheckCircleDashed = 'check-circle-dashed',
  CheckCircleFilled = 'check-circle-filled',
  CheckCircle = 'check-circle',
  Check = 'check',
  Clock = 'clock',
  Close = 'close',
  CommentDots = 'comment-dots',
  CommentLines = 'comment-lines',
  CommentNext = 'comment-next',
  Comment = 'comment',
  Comments = 'comments',
  CommunexSmall = 'communex-small',
  Communex = 'communex',
  Compare = 'compare',
  Comparison = 'comparison',
  CopyToClipboard = 'copy-to-clipboard',
  Disk = 'disk',
  DocChanges = 'doc-changes',
  DocChart = 'doc-chart',
  DocCode = 'doc-code',
  DocCompressed = 'doc-compressed',
  DocExpand = 'doc-expand',
  DocImage = 'doc-image',
  DocNew = 'doc-new',
  DocWiki = 'doc-wiki',
  DocSymlink = 'doc-symlink',
  DocText = 'doc-text',
  DocVersions = 'doc-versions',
  Document = 'document',
  Documents = 'documents',
  DotsAnim = 'dots-anim',
  Download = 'download',
  Earth = 'earth',
  Enhancement = 'enhancement',
  ExternalLink = 'external-link',
  EyeSlash = 'eye-slash',
  Eye = 'eye',
  OldFeed = 'old-feed',
  Feed = 'feed',
  FileAdditionSolid = 'file-addition-solid',
  FileAddition = 'file-addition',
  FileDeletionSolid = 'file-deletion-solid',
  FileDeletion = 'file-deletion',
  FileModifiedSolid = 'file-modified-solid',
  FileModified = 'file-modified',
  FileTree = 'file-tree',
  Filter = 'filter',
  FolderAdd = 'folder-add',
  FolderOpen = 'folder-open',
  Folder = 'folder',
  FolderYellowOpen = 'folder-yellow-open',
  FolderYellowClose = 'folder-yellow-close',
  FolderYellowAdd = 'folder-yellow-add',
  GoBack = 'go-back',
  Grip = 'grip',
  Hamburger = 'hamburger',
  HeartOutline = 'heart-o',
  Heart = 'heart',
  History = 'history',
  Home = 'home',
  Information = 'information',
  Issues = 'issues',
  Label = 'label',
  Labels = 'labels',
  LicenseSm = 'license-sm',
  License = 'license',
  ListBulleted = 'list-bulleted',
  List = 'list',
  LiveStream = 'live-stream',
  Media = 'media',
  Milestones = 'milestones',
  MonitorOutline = 'monitor-o',
  Move = 'move',
  NotificationsOff = 'notifications-off',
  Notifications = 'notifications',
  Overview = 'overview',
  PaperAirplane = 'paper-airplane',
  Paperclip = 'paperclip',
  PencilSquare = 'pencil-square',
  Pencil = 'pencil',
  Pin = 'pin',
  PinGold = 'pin-gold',
  PinO = 'pin-o',
  Power = 'power',
  Profile = 'profile',
  Projects = 'projects',
  QuestionOutline = 'question-o',
  Remove = 'remove',
  Letters = 'rename',
  Reply = 'reply',
  Refresh = 'retry',
  ReviewCheckmark = 'review-checkmark',
  Rocket = 'rocket',
  Substitute = 'substitute',
  SearchSm = 'search-sm',
  Search = 'search',
  Send = 'send',
  Settings = 'settings',
  Share = 'share',
  SoftWrap = 'soft-wrap',
  SortHighest = 'sort-highest',
  SortLowest = 'sort-lowest',
  StarOutline = 'star-o',
  Star = 'star',
  Table2 = 'table-2',
  Table = 'table',
  Tag = 'tag',
  Title = 'title',
  TodoAdd = 'todo-add',
  Trash = 'trash',
  Unlink = 'unlink',
  Upload = 'upload',
  User = 'user',
  Users = 'users',
  VideoCall = 'video-call',
  VolumeUp = 'volume-up',
  Work = 'work',
  TimeOut = 'time-out',
  Timer = 'timer',
  EllipsisH = 'ellipsis_h',
  EllipsisV = 'ellipsis_v',

  // Gitlab
  Abuse = 'abuse',
  Accessibility = 'accessibility',
  Account = 'account',
  Api = 'api',
  ApprovalSolid = 'approval-solid',
  Approval = 'approval',
  Archive = 'archive',
  Assignee = 'assignee',
  At = 'at',
  AttentionSolidSm = 'attention-solid-sm',
  AttentionSolid = 'attention-solid',
  Attention = 'attention',
  Autoplay = 'autoplay',
  Bitbucket = 'bitbucket',
  Bold = 'bold',
  Book = 'book',
  BranchDeleted = 'branch-deleted',
  Branch = 'branch',
  Bug = 'bug',
  Building = 'building',
  Cancel = 'cancel',
  CanceledCircle = 'canceled-circle',
  Car = 'car',
  CatalogCheckmark = 'catalog-checkmark',
  Chart = 'chart',
  CheckSm = 'check-sm',
  CheckXs = 'check-xs',
  CherryPickCommit = 'cherry-pick-commit',
  ChevronDoubleLgLeft = 'chevron-double-lg-left',
  ChevronDoubleLgRight = 'chevron-double-lg-right',
  ChevronLgDown = 'chevron-lg-down',
  ChevronLgLeft = 'chevron-lg-left',
  ChevronLgRight = 'chevron-lg-right',
  ChevronDown = 'chevron-down',
  ChevronRight = 'chevron-right',
  ChevronLeft = 'chevron-left',
  ChevronLgUp = 'chevron-lg-up',
  ChevronUp = 'chevron-up',
  ClearAll = 'clear-all',
  Clear = 'clear',
  CloseXs = 'close-xs',
  CloudGear = 'cloud-gear',
  CloudPod = 'cloud-pod',
  CloudTerminal = 'cloud-terminal',
  Code = 'code',
  CollapseLeft = 'collapse-left',
  CollapseRight = 'collapse-right',
  CollapseSolid = 'collapse-solid',
  Collapse = 'collapse',
  Commit = 'commit',
  Compass = 'compass',
  Connected = 'connected',
  ContainerImage = 'container-image',
  CreditCard = 'credit-card',
  DashCircle = 'dash-circle',
  Dash = 'dash',
  Dashboard = 'dashboard',
  Deployments = 'deployments',
  DetailsBlock = 'details-block',
  Diagram = 'diagram',
  Discord = 'discord',
  DotGrid = 'dot-grid',
  DottedCircle = 'dotted-circle',
  DoubleHeadedArrow = 'double-headed-arrow',
  Dumbbell = 'dumbbell',
  Duplicate = 'duplicate',
  EntityBlocked = 'entity-blocked',
  Environment = 'environment',
  EpicClosed = 'epic-closed',
  Error = 'error',
  ExpandDown = 'expand-down',
  ExpandLeft = 'expand-left',
  ExpandRight = 'expand-right',
  ExpandUp = 'expand-up',
  Expand = 'expand',
  Expire = 'expire',
  Export = 'export',
  FaceNeutral = 'face-neutral',
  FaceUnhappy = 'face-unhappy',
  FalsePositive = 'false-positive',
  FeatureFlagDisabled = 'feature-flag-disabled',
  FeatureFlag = 'feature-flag',
  Fire = 'fire',
  FirstContribution = 'first-contribution',
  Flag = 'flag',
  Food = 'food',
  Fork = 'fork',
  Formula = 'formula',
  GitMerge = 'git-merge',
  Git = 'git',
  Gitea = 'gitea',
  Github = 'github',
  Google = 'google',
  Group = 'group',
  Heading = 'heading',
  Highlight = 'highlight',
  Hook = 'hook',
  Hourglass = 'hourglass',
  ImageCommentDark = 'image-comment-dark',
  ImageCommentLight = 'image-comment-light',
  Import = 'import',
  Incognito = 'incognito',
  InfrastructureRegistry = 'infrastructure-registry',
  IssueBlock = 'issue-block',
  IssueClose = 'issue-close',
  IssueClosed = 'issue-closed',
  IssueNew = 'issue-new',
  IssueOpenM = 'issue-open-m',
  IssueTypeFeatureFlag = 'issue-type-feature-flag',
  IssueTypeFeature = 'issue-type-feature',
  IssueTypeIncident = 'issue-type-incident',
  IssueTypeIssue = 'issue-type-issue',
  IssueTypeKeyresult = 'issue-type-keyresult',
  IssueTypeMaintenance = 'issue-type-maintenance',
  IssueTypeObjective = 'issue-type-objective',
  IssueTypeRequirements = 'issue-type-requirements',
  IssueTypeTask = 'issue-type-task',
  IssueTypeTestCase = 'issue-type-test-case',
  IssueTypeTicket = 'issue-type-ticket',
  IssueUpdate = 'issue-update',
  Italic = 'italic',
  Iteration = 'iteration',
  Key = 'key',
  Keyboard = 'keyboard',
  KubernetesAgent = 'kubernetes-agent',
  Kubernetes = 'kubernetes',
  Leave = 'leave',
  LevelUp = 'level-up',
  Link = 'link',
  Linkedin = 'linkedin',
  ListIndent = 'list-indent',
  ListNumbered = 'list-numbered',
  ListOutdent = 'list-outdent',
  ListTask = 'list-task',
  LivePreview = 'live-preview',
  LocationDot = 'location-dot',
  Location = 'location',
  LockOpen = 'lock-open',
  LockClosed = 'lock',
  Log = 'log',
  LongArrow = 'long-arrow',
  MachineLearning = 'machine-learning',
  Mail = 'mail',
  MarkdownMarkSolid = 'markdown-mark-solid',
  MarqueeSelection = 'marquee-selection',
  Mastodon = 'mastodon',
  Maximize = 'maximize',
  MediaBroken = 'media-broken',
  MergeRequestCloseM = 'merge-request-close-m',
  MergeRequestClose = 'merge-request-close',
  MergeRequestOpen = 'merge-request-open',
  MergeRequest = 'merge-request',
  Merge = 'merge',
  Minimize = 'minimize',
  MobileIssueClose = 'mobile-issue-close',
  Mobile = 'mobile',
  MonitorLines = 'monitor-lines',
  Monitor = 'monitor',
  Namespace = 'namespace',
  Nature = 'nature',
  Object = 'object',
  Organization = 'organization',
  Package = 'package',
  PartnerVerified = 'partner-verified',
  Pause = 'pause',
  Pipeline = 'pipeline',
  Planning = 'planning',
  Play = 'play',
  Plus = 'plus',
  PlusSquareO = 'plus-square-o',
  PlusSquare = 'plus-square',
  Pod = 'pod',
  Podcast = 'podcast',
  Preferences = 'preferences',
  Progress = 'progress',
  Project = 'project',
  PushRules = 'push-rules',
  Question = 'question',
  QuickActions = 'quick-actions',
  Quota = 'quota',
  Quote = 'quote',
  Redo = 'redo',
  RegularExpression = 'regular-expression',
  RemoveAll = 'remove-all',
  Repeat = 'repeat',
  Requirements = 'requirements',
  ReviewList = 'review-list',
  ReviewWarning = 'review-warning',
  RocketLaunch = 'rocket-launch',
  Rss = 'rss',
  Scale = 'scale',
  ScrollHandle = 'scroll-handle',
  ScrollDown = 'scroll_down',
  ScrollUp = 'scroll_up',
  SearchDot = 'search-dot',
  SearchMinus = 'search-minus',
  SearchPlus = 'search-plus',
  SearchResults = 'search-results',
  SeverityCritical = 'severity-critical',
  SeverityHigh = 'severity-high',
  SeverityInfo = 'severity-info',
  SeverityLow = 'severity-low',
  SeverityMedium = 'severity-medium',
  SeverityUnknown = 'severity-unknown',
  Shield = 'shield',
  SidebarRight = 'sidebar-right',
  Sidebar = 'sidebar',
  Skype = 'skype',
  SlightFrown = 'slight-frown',
  SlightSmile = 'slight-smile',
  SmartCard = 'smart-card',
  Smile = 'smile',
  Smiley = 'smiley',
  Snippet = 'snippet',
  SoftUnwrap = 'soft-unwrap',
  Spam = 'spam',
  Spinner = 'spinner',
  StageAll = 'stage-all',
  StatusActive = 'status-active',
  StatusAlert = 'status-alert',
  StatusCancelled = 'status-cancelled',
  StatusFailed = 'status-failed',
  StatusHealth = 'status-health',
  StatusNeutral = 'status-neutral',
  StatusPaused = 'status-paused',
  StatusRunning = 'status-running',
  StatusScheduled = 'status-scheduled',
  StatusStopped = 'status-stopped',
  StatusSuccess = 'status-success',
  StatusWaiting = 'status-waiting',
  Status = 'status',
  StatusCanceled = 'status_canceled',
  StatusCanceledBorderless = 'status_canceled_borderless',
  StatusClosed = 'status_closed',
  StatusCreated = 'status_created',
  StatusCreatedBorderless = 'status_created_borderless',
  StatusFailedBorderless = 'status_failed_borderless',
  StatusManual = 'status_manual',
  StatusManualBorderless = 'status_manual_borderless',
  StatusNotfound = 'status_notfound',
  StatusNotfoundBorderless = 'status_notfound_borderless',
  StatusOpen = 'status_open',
  StatusPending = 'status_pending',
  StatusPendingBorderless = 'status_pending_borderless',
  StatusPreparing = 'status_preparing',
  StatusPreparingBorderless = 'status_preparing_borderless',
  StatusRunningBorderless = 'status_running_borderless',
  StatusScheduledBorderless = 'status_scheduled_borderless',
  StatusSkipped = 'status_skipped',
  StatusSkippedBorderless = 'status_skipped_borderless',
  StatusSuccessBorderless = 'status_success_borderless',
  StatusSuccessSolid = 'status_success_solid',
  StatusWarning = 'status_warning',
  StatusWarningBorderless = 'status_warning_borderless',
  Stop = 'stop',
  Strikethrough = 'strikethrough',
  Subgroup = 'subgroup',
  Subscript = 'subscript',
  Superscript = 'superscript',
  Symlink = 'symlink',
  Tablet = 'tablet',
  Tachometer = 'tachometer',
  TanukiAi = 'tanuki-ai',
  TanukiVerified = 'tanuki-verified',
  Tanuki = 'tanuki',
  TaskDone = 'task-done',
  Template = 'template',
  Terminal = 'terminal',
  Terraform = 'terraform',
  TextDescription = 'text-description',
  ThumbDown = 'thumb-down',
  ThumbUp = 'thumb-up',
  ThumbtackSolid = 'thumbtack-solid',
  Thumbtack = 'thumbtack',
  TodoDone = 'todo-done',
  Token = 'token',
  TrendDown = 'trend-down',
  TrendStatic = 'trend-static',
  TrendUp = 'trend-up',
  TriggerSource = 'trigger-source',
  Unapproval = 'unapproval',
  Unassignee = 'unassignee',
  Underline = 'underline',
  UnstageAll = 'unstage-all',
  Upgrade = 'upgrade',
  WarningSolid = 'warning-solid',
  Warning = 'warning',
  Weight = 'weight',
  X = 'x',
  Icons = 'icons-set',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  Campus = 'monitor-o',
}

export const enum PostMenuActionTypeEnum {
  All,
  Share,
  Download,
  Send,
}

export const enum RouteWithSearchEnum {
  GROUPS = 'Groups',
  USERS = 'Users',
  PAGES = 'Pages',
  TOPICS = 'Topics',
}

export const enum SettingsQueryActionEnum {
  Notifications = 'notifications',
}

export const enum GroupsQueryActionEnum {
  Invites = 'invites',
  Tab = 'tab',
}

export const enum MentionModeEnum {
  //NOTE: ByCaret - when user is typing " @, # " in the input field
  ByCaret = 'byCaret',
  //NOTE: ByMentionInput - when user is typing in the special mention input
  ByMentionInput = 'byMentionInput',
}

export const enum SortingTypeEnum {
  Name = 'Name',
  Group = 'Group',
  Author = 'Author',
  Date = 'Date',
  Size = 'Size',
  Type = 'Type',
  UnreadMessagesCount = 'UnreadMessagesCount',
  StatsMembers = 'StatsMembers',
  StatsMessages = 'StatsMessages',

  /* Documents sorting */
  DateCreated = 'DateCreated',
  EditedAt = 'EditedAt',
  GroupTitle = 'GroupTitle',
  OwnerName = 'OwnerName',
  Official = 'Official',
}

export const enum SortingDirectionEnum {
  Asc = 'asc',
  Desc = 'desc',
}

export const enum EnvVariablesEnum {
  CompaniesListWithDisabledMenuItems = 'CompaniesListWithDisabledMenuItems',
  CompaniesListWithCustomLocales = 'CompaniesListWithCustomLocales',
  CompaniesListWithDisabledCampus = 'CompaniesListWithDisabledCampus',
  CompaniesListCampusLink = 'CompaniesListCampusLink',
  CompaniesListWithDisabledQuickSearch = 'CompaniesListWithDisabledQuickSearch',
  CompaniesListWithPermanentlyPinnedGroups = 'CompaniesListWithPermanentlyPinnedGroups',
  CompaniesListWithDocsInNewWindow = 'CompaniesListWithDocsInNewWindow',
  CompaniesListWithDisabledUsersFilterInDocsBrowser = 'CompaniesListWithDisabledUsersFilterInDocsBrowser',

  SaarlandSecondaryNetworks = 'SaarlandSecondaryNetworks',
}

export const enum GeneratePasswordEnum {
  Autogenerate,
  SetManually,
}

export const enum GenderEnum {
  Unknown = 'Unknown',
  Male = 'Male',
  Female = 'Female',
  NotApplicable = 'NotApplicable',
}

export const enum AdminInviteUserModeEnum {
  Invite = 'invite',
  Register = 'register',
}

export const enum NotificationTemplatesTypeEnum {
  MessageReply = 'MessageReply',
  NewMessage = 'NewMessage',
  InvitedToGroup = 'InvitedToGroup',
  NewMessageInGroup = 'NewMessageInGroup',
  GetNewFollowers = 'GetNewFollowers',
  PasswordRecovery = 'PasswordRecovery',
  LikedPostedMessage = 'LikedPostedMessage',
  NewBadge = 'NewBadge',
  ActivateAccount = 'ActivateAccount',
  NewPrivateMessage = 'NewPrivateMessage',
  UserInvitationNotification = 'UserInvitationNotification',
  AskFillingUserInfo = 'AskFillingUserInfo',
  ConfirmEmail = 'ConfirmEmail',
  NewSystemBadge = 'NewSystemBadge',
  LicenseCapacityRunningOut = 'LicenseCapacityRunningOut',
  LicenseCapacityExceeded = 'LicenseCapacityExceeded',
  CommentToYourUserItem = 'CommentToYourUserItem',
  NewComment = 'NewComment',
  MentionedInComment = 'MentionedInComment',
  NewAnnouncement = 'NewAnnouncement',
  NewAnnouncementInGroup = 'NewAnnouncementInGroup',
  RequestJoinToGroup = 'RequestJoinToGroup',
  UnregisteredNewMessage = 'UnregisteredNewMessage',
  NewEvent = 'NewEvent',
  EventAttend = 'EventAttend',
  RequestRegistrationToNetwork = 'RequestRegistrationToNetwork',
  UnregisteredNewBadge = 'UnregisteredNewBadge',
  NewSubordinate = 'NewSubordinate',
  NewManager = 'NewManager',
  NewManagerWithInvite = 'NewManagerWithInvite',
  NewSubordinateWithInvite = 'NewSubordinateWithInvite',
  ForwardMessage = 'ForwardMessage',
  InvitePerson = 'InvitePerson',
  NewGrouppedPrivateMessageOne = 'NewGrouppedPrivateMessageOne',
  NewGrouppedPrivateMessageMyltiple = 'NewGrouppedPrivateMessageMyltiple',
  NewGrouppedPrivateMessageMyltipleTillFour = 'NewGrouppedPrivateMessageMyltipleTillFour',
  InviteAdminToNetwork = 'InviteAdminToNetwork',
  InviteExternalUserToGroup = 'InviteExternalUserToGroup',
  UserWelcomeToNetwork = 'UserWelcomeToNetwork',
  ExternalUserWelcomeToGroup = 'ExternalUserWelcomeToGroup',
  RegisterPerson = 'RegisterPerson',
  SendEmailAlreadyRegistered = 'SendEmailAlreadyRegistered',
  InviteToVideoConference = 'InviteToVideoConference',
  InviteToVideoChat = 'InviteToVideoChat',
  DeleteCompany = 'DeleteCompany',
  UserItemArchive = 'UserItemArchive',
  DigestTemplate = 'DigestTemplate',
  TaskCreated = 'TaskCreated',
  TaskClosed = 'TaskClosed',
  TaskReopened = 'TaskReopened',
  TaskArchived = 'TaskArchived',
  TaskDearchived = 'TaskDearchived',
  TaskTitleUpdated = 'TaskTitleUpdated',
  TaskDescriptionUpdated = 'TaskDescriptionUpdated',
  TaskAssigneeChanged = 'TaskAssigneeChanged',
  TaskMilestoneChanged = 'TaskMilestoneChanged',
  TaskDateStartUpdated = 'TaskDateStartUpdated',
  TaskDateDueUpdated = 'TaskDateDueUpdated',
  TaskTagsAdded = 'TaskTagsAdded',
  TaskTagsRemoved = 'TaskTagsRemoved',
  TaskFilesAdded = 'TaskFilesAdded',
  TaskFilesRemoved = 'TaskFilesRemoved',
  TaskWikiesAdded = 'TaskWikiesAdded',
  TaskWikiesRemoved = 'TaskWikiesRemoved',
  TaskLinksAdded = 'TaskLinksAdded',
  TaskLinksRemoved = 'TaskLinksRemoved',
  TaskParticipantsAdded = 'TaskParticipantsAdded',
  TaskParticipantsRemoved = 'TaskParticipantsRemoved',
  TaskCommentCreated = 'TaskCommentCreated',
  TaskCommentUpdated = 'TaskCommentUpdated',
  TaskCommentDeleted = 'TaskCommentDeleted',
  TaskMoved = 'TaskMoved',
  TaskReporterChanged = 'TaskReporterChanged',
  TaskAssigneeAdded = 'TaskAssigneeAdded',
  TaskAssigneeRemoved = 'TaskAssigneeRemoved',
  TaskReporterAdded = 'TaskReporterAdded',
  TaskReporterRemoved = 'TaskReporterRemoved',
  TaskMilestoneAdded = 'TaskMilestoneAdded',
  TaskMilestoneRemoved = 'TaskMilestoneRemoved',
  TaskDateStartAdded = 'TaskDateStartAdded',
  TaskDateStartRemoved = 'TaskDateStartRemoved',
  TaskDateDueAdded = 'TaskDateDueAdded',
  TaskDateDueRemoved = 'TaskDateDueRemoved',
  BirthDayWeeklyTemplate = 'BirthDayWeeklyTemplate',
  BlockerUpdated = 'BlockerUpdated',
  BlockerDeleted = 'BlockerDeleted',
  EmailFooter = 'EmailFooter',
  LikedPostedComment = 'LikedPostedComment',
}

export const enum DocEditAccessEnum {
  Off = 'Off',
  All = 'All',
  Administrators = 'Administrators',
  AllExceptExternal = 'AllExceptExternal',
}

export enum AppListItemTypeEnum {
  People = 'people',
  Group = 'group',
  Badge = 'badge',
  Wiki = 'wiki',
  File = 'file',
  Network = 'network',
  Page = 'page',
}

export const enum IndexedDBStoresEnum {
  Images = 'images',
}

export const enum PostShareToEnum {
  Group = 'Group',
  PrivateMessage = 'PrivateMessage',
}

export const enum GroupSelectAccessEnum {
  RemoveGroup,
  RepickGroup,
  PickAllNetwork,
  RepickProject,
}

export const enum GroupAutoSelectModeEnum {
  None = 'None',
  FirstAvailableGroup = 'FirstAvailableGroup',
  ByRouteId = 'ByRouteId',
  FromCurrentProject = 'FromCurrentProject',
}

export const enum DateFormatEnum {
  Date11 = 'dd MMM yyyy',
  Date16 = 'yyyy-MM-dd HH:mm',
  Date23 = 'yyyy-MM-dd HH:mm:ss zzz',
  DateIso = 'yyyy-MM-ddTHH:mm:ss',
  DateIsoUtc = 'yyyy-MM-ddTHH:mm:ssZ',
}

export enum DocsTableHeaderPropEnum {
  Icon = 'icon',
  Name = 'data.name',
  Group = 'data.group.title',
  Author = 'data.author.fullName',
  Date = 'data.editedAt',
  Official = 'data.official',
}
