import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { LocalNotifications } from '@capacitor/local-notifications';
import { PushNotifications } from '@capacitor/push-notifications';
import type { ActionPerformed, PushNotificationSchema } from '@capacitor/push-notifications';
import { Badge } from '@capawesome/capacitor-badge';
import { isPlatform } from '@ionic/vue';
import { MessageTypeEnum } from '@/enums';
import { WikiActionEnum } from '@/enums/wiki';
import { NotificationsPushActionsEnum } from '@/enums/notifications';
import { htmlToText, isNativeMobile, useLoading, useTaskManagement, useToasts, useWiki } from '@/helpers';
import { useI18n } from '@/i18n';
import router, { openGroup, ROUTES_NAME } from '@/router';
import { $api } from '@/services';
import type { AppState } from '@/store';
import { useAppStore, useChatStore, useMessengerStore, useNotificationsStore, useProjectsStore } from '@/store';
import type { MessageModel, PushModel } from '@/types';

type IUseNotifications = {
  /**
   * Initialize local notifications.
   */
  initLocalNotifications(): Promise<void>;
  /**
   * Schedule a local notification.
   */
  scheduleLocalNotification(newMess: MessageModel): Promise<void>;
  /**
   * Cancel local notifications.
   */
  cancelLocalNotifications(chainId: number): Promise<void>;
  /**
   * Reset badge count.
   */
  resetBadgeCount(): Promise<void>;
  /**
   * Set unread notifications count.
   */
  setBadgeCount(): Promise<void>;
  /**
   * Initialize push notifications.
   */
  initPushNotifications(): Promise<void>;
  /**
   * Cancel push notifications.
   */
  cancelPushNotifications(): Promise<void>;
  /**
   * Handle notification action, either from push or click.
   * @param id Notification identifier.
   * @param isRead Indicates if the notification was already read.
   * @param type Notification type.
   * @param entityId The entity ID to be processed.
   */
  handleNotificationClick(
    id: number,
    isRead: boolean,
    type: NotificationsPushActionsEnum,
    entityId: number
  ): Promise<void>;
  /**
   * Mark notifications for a specific chain as read.
   * @param chainId The ID of the chain to mark notifications for.
   */
  markNotificationsForChain(chainId: number): Promise<void>;
};

let pushInitialized = false;

export function useNotifications(): IUseNotifications {
  //#region Variables
  const { t } = useI18n();
  //#endregion

  //#region Private Methods
  /**
   * Update badge count by summing unread notifications and messages.
   */
  async function _updateBadgeCount(): Promise<void> {
    await useNotificationsStore().unreadNotifications();
    const notificationsCount = useNotificationsStore().getUnreadNotificationsCount;
    await useMessengerStore().updateUnreadCounter();
    const messagesCount = useMessengerStore().getUnreadMessagesCount;
    const total = notificationsCount + messagesCount;
    const badgeCount = total > 99 ? 99 : total;
    await Badge.set({ count: badgeCount });
  }

  /**
   * Convert HTML message to plain text.
   * @param newMess The message model.
   * @returns The plain text body.
   */
  function _getMessageBody(newMess: MessageModel): string {
    if (newMess.messageType === MessageTypeEnum.Quote) {
      return newMess?.originalMessage ? t('messenger.chatPage.forwarded') : '';
    } else if (newMess.messageType === MessageTypeEnum.Forward || newMess.messageType === MessageTypeEnum.ForwardOld) {
      return t('messenger.chatPage.forwarded');
    } else {
      const withFiles =
        newMess.attachedFiles.count > 0 ||
        (newMess?.forwardedAttachedFiles && newMess.forwardedAttachedFiles.count > 0);
      return newMess.bodyHtml ? htmlToText(newMess.bodyHtml) : withFiles ? t('messenger.messengerSend.file') : '';
    }
  }

  /**
   * Log a message and show a toast.
   * @param message The message to log.
   * @param type The log type.
   */
  function _logAndShowToast(message: string, type: string): void {
    console.log(`[INFO] ${type}: `, message);
    useToasts().showSonnerToast(message, false);
  }

  /**
   * Check if badge is supported.
   * @returns True if supported.
   */
  async function _isBadgeSupported(): Promise<boolean> {
    if (!isNativeMobile || !(await Badge.isSupported())) {
      return false;
    }
    return true;
  }

  /**
   * Handle local notification actions.
   * @param chainId The chain id from the notification.
   */
  async function _handleLocalNotification(chainId: number): Promise<void> {
    if (chainId > 0) {
      await router.push({
        name: ROUTES_NAME.MESSENGER_CHAT_BY_CHAIN,
        params: { id: chainId },
      });
      useMessengerStore().resetUnreadMessagesCount(chainId);
      // Since we have reset the unread messages count, we simply update the badge count.
      await _updateBadgeCount();
    }
  }
  //#endregion

  //#region Notification Handlers
  function handleNone(id: number): void {
    console.warn(`[WARN] No action for notification ${id} of type: ${NotificationsPushActionsEnum.None}`);
    _logAndShowToast('No action for this notification type.', 'none');
  }

  /**
   * @todo Currently not implemented; can later open a browser window if needed.
   */
  function handleExternalUrl(id: number): void {
    console.warn(`[WARN] No action for notification ${id} of type: ${NotificationsPushActionsEnum.ExternalUrlCreate}`);
    _logAndShowToast('External URL action (not implemented).', 'external-url');
  }

  async function handlePostNotification(id: number): Promise<void> {
    await router.push({
      name: ROUTES_NAME.POST_BY_ID,
      params: { id },
    });
  }

  async function handleMessageNotification(id: number): Promise<void> {
    const result = await useChatStore().getChainById(id);
    if (!result) {
      console.error('[ERROR] Failed to get chat by chain id: ', id);
      return;
    }
    await useChatStore().getMessagesByChainId(Number(id));
    await router.push({
      name: ROUTES_NAME.MESSENGER_CHAT_BY_CHAIN,
      params: { id },
    });
  }

  async function handleUserNotification(id: number): Promise<void> {
    await router.push({
      name: ROUTES_NAME.USER_BY_ID,
      params: { id },
    });
  }

  async function handleGroupNotification(id: number): Promise<void> {
    await openGroup(id);
  }

  async function handleWikiNotification(id: number): Promise<void> {
    await useWiki().handleAction({ type: WikiActionEnum.ToCurrent, id });
  }

  async function handleFileNotification(id: number): Promise<void> {
    await router.push({
      name: ROUTES_NAME.FILE_BY_ID,
      params: { id },
    });
  }

  async function handleTaskNotification(id: number): Promise<void> {
    if (!useTaskManagement().getAccessToTaskManagement()) {
      console.warn('User does not have access to task management');
      return;
    }
    await useLoading().create(t('loading'));
    try {
      const task = await useProjectsStore().taskById(id);
      if (!task) {
        throw new Error(`Task with id ${id} does not exist`);
      }
      const projectId = useProjectsStore().getProjectIdByTaskId(id);
      if (!projectId) {
        throw new Error(`Task with id ${id} does not have a project`);
      }
      let project = useProjectsStore().getProjectById(projectId);
      if (!project) {
        project = await useProjectsStore().projectById(projectId);
      }
      if (!project) {
        throw new Error(`Project with id ${projectId} does not exist`);
      }
      await router.push({
        query: { showTaskId: id, projectId },
      });
    } catch (e: any) {
      console.error('[ERROR] Error handling task notification: ', e);
    } finally {
      await useLoading().dismiss();
    }
  }

  /**
   * Mapping of notification types to their handler functions.
   */
  const _notificationsMap: Record<NotificationsPushActionsEnum, (id: number) => void | Promise<void>> = Object.freeze({
    // Base
    [NotificationsPushActionsEnum.None]: handleNone,
    [NotificationsPushActionsEnum.ExternalUrlCreate]: handleExternalUrl,
    // Post notifications
    [NotificationsPushActionsEnum.Post]: handlePostNotification,
    [NotificationsPushActionsEnum.PostInGroup]: handlePostNotification,
    [NotificationsPushActionsEnum.PostCreate]: handlePostNotification,
    [NotificationsPushActionsEnum.PostLiked]: handlePostNotification,
    [NotificationsPushActionsEnum.PostAnnouncement]: handlePostNotification,
    [NotificationsPushActionsEnum.PostAnnouncementInGroup]: handlePostNotification,
    [NotificationsPushActionsEnum.PostPlannedCreated]: handlePostNotification,
    [NotificationsPushActionsEnum.PostPlannedFailed]: handlePostNotification,
    // Post comment notifications
    [NotificationsPushActionsEnum.PostCommentCreate]: handlePostNotification,
    [NotificationsPushActionsEnum.PostCommentAfterYouComment]: handlePostNotification,
    [NotificationsPushActionsEnum.PostCommentToYourUserItem]: handlePostNotification,
    [NotificationsPushActionsEnum.PostCommentMarkedAsHelpful]: handlePostNotification,
    [NotificationsPushActionsEnum.PostCommentCreateMentioned]: handlePostNotification,
    [NotificationsPushActionsEnum.PostCommentLiked]: handlePostNotification,
    // Message notifications
    [NotificationsPushActionsEnum.Message]: handleMessageNotification,
    [NotificationsPushActionsEnum.MessageCreate]: handleMessageNotification,
    // User notifications
    [NotificationsPushActionsEnum.User]: handleUserNotification,
    [NotificationsPushActionsEnum.UserCreate]: handleUserNotification,
    [NotificationsPushActionsEnum.UserNewFollower]: handleUserNotification,
    [NotificationsPushActionsEnum.UserNewManager]: handleUserNotification,
    [NotificationsPushActionsEnum.UserNewSubordinate]: handleUserNotification,
    [NotificationsPushActionsEnum.UserNewLevel]: handleUserNotification,
    // Group notifications
    [NotificationsPushActionsEnum.Group]: handleGroupNotification,
    [NotificationsPushActionsEnum.GroupCreate]: handleGroupNotification,
    [NotificationsPushActionsEnum.GroupAdded]: handleGroupNotification,
    [NotificationsPushActionsEnum.GroupInvited]: handleGroupNotification,
    [NotificationsPushActionsEnum.GroupRequestJoin]: handleGroupNotification,
    [NotificationsPushActionsEnum.GroupRequestJoinDeny]: handleGroupNotification,
    // Wiki notifications
    [NotificationsPushActionsEnum.Wiki]: handleWikiNotification,
    [NotificationsPushActionsEnum.WikiCreate]: handleWikiNotification,
    [NotificationsPushActionsEnum.WikiNewVersion]: handleWikiNotification,
    [NotificationsPushActionsEnum.WikiMentioned]: handleWikiNotification,
    // File notifications
    [NotificationsPushActionsEnum.File]: handleFileNotification,
    [NotificationsPushActionsEnum.FileCreate]: handleFileNotification,
    [NotificationsPushActionsEnum.FileNewVersion]: handleFileNotification,
    // Task notifications
    [NotificationsPushActionsEnum.Task]: handleTaskNotification,
    [NotificationsPushActionsEnum.TaskCreated]: handleTaskNotification,
    [NotificationsPushActionsEnum.TaskClosed]: handleTaskNotification,
    [NotificationsPushActionsEnum.TaskReopened]: handleTaskNotification,
    [NotificationsPushActionsEnum.TaskArchived]: handleTaskNotification,
    [NotificationsPushActionsEnum.TaskDeArchived]: handleTaskNotification,
    [NotificationsPushActionsEnum.TaskTitleUpdated]: handleTaskNotification,
    [NotificationsPushActionsEnum.TaskDescriptionUpdated]: handleTaskNotification,
    [NotificationsPushActionsEnum.TaskAssigneeChanged]: handleTaskNotification,
    [NotificationsPushActionsEnum.TaskMilestoneChanged]: handleTaskNotification,
    [NotificationsPushActionsEnum.TaskDateStartUpdated]: handleTaskNotification,
    [NotificationsPushActionsEnum.TaskDateDueUpdated]: handleTaskNotification,
    [NotificationsPushActionsEnum.TaskTagsAdded]: handleTaskNotification,
    [NotificationsPushActionsEnum.TaskTagsRemoved]: handleTaskNotification,
    [NotificationsPushActionsEnum.TaskFilesAdded]: handleTaskNotification,
    [NotificationsPushActionsEnum.TaskFilesRemoved]: handleTaskNotification,
    [NotificationsPushActionsEnum.TaskWikisAdded]: handleTaskNotification,
    [NotificationsPushActionsEnum.TaskWikisRemoved]: handleTaskNotification,
    [NotificationsPushActionsEnum.TaskLinksAdded]: handleTaskNotification,
    [NotificationsPushActionsEnum.TaskLinksRemoved]: handleTaskNotification,
    [NotificationsPushActionsEnum.TaskParticipantsAdded]: handleTaskNotification,
    [NotificationsPushActionsEnum.TaskParticipantsRemoved]: handleTaskNotification,
    [NotificationsPushActionsEnum.TaskCommentCreated]: handleTaskNotification,
    [NotificationsPushActionsEnum.TaskCommentUpdated]: handleTaskNotification,
    [NotificationsPushActionsEnum.TaskCommentDeleted]: handleTaskNotification,
    [NotificationsPushActionsEnum.TaskMoved]: handleTaskNotification,
    [NotificationsPushActionsEnum.TaskReporterChanged]: handleTaskNotification,
  });
  //#endregion

  //#region Unified Notification Processing

  /**
   * Unified function to process notifications for both push and click events.
   * @param notificationId The notification identifier.
   * @param type The notification type.
   * @param entityId The entity (or push model) from which an ID is extracted.
   * @param markAsRead Whether to mark the notification as read.
   * @param mode Mode of invocation: 'click' or 'push'.
   */
  async function _handleNotification(
    notificationId: number,
    type: NotificationsPushActionsEnum,
    entityId: number,
    markAsRead: boolean,
    mode: 'click' | 'push'
  ): Promise<void> {
    const handler = _notificationsMap[type];
    if (!handler) {
      throw new Error(`Unknown notification type: ${type}`);
    }
    await handler(entityId);
    if (markAsRead) {
      const result = await useNotificationsStore().markAs([notificationId], true);
      if (mode === 'click' && !result) {
        useToasts().showSonnerToast('Failed to mark notification as read.', false);
      }
    }
    await _updateBadgeCount();
  }
  //#endregion

  //#region Public Methods
  async function initLocalNotifications(): Promise<void> {
    if (isNativeMobile) {
      let permStatus = await LocalNotifications.checkPermissions();
      if (permStatus.display === 'prompt') {
        permStatus = await LocalNotifications.requestPermissions();
      }
      if (permStatus.display !== 'granted') {
        console.debug('[DEBUG] Permissions for local notifications denied.');
      }

      await LocalNotifications.registerActionTypes({
        types: [
          {
            id: 'NEW_CHAT_MESSAGE',
            actions: [
              {
                id: 'view',
                title: t('open'),
              },
            ],
          },
        ],
      });

      await LocalNotifications.addListener('localNotificationActionPerformed', (notification: any) => {
        useAppStore().$patch((state: AppState) => {
          state.fromMobilePush = true;
        });
        _handleLocalNotification(notification.notification.id);
      });
    }
  }

  async function scheduleLocalNotification(newMess: MessageModel): Promise<void> {
    const scheduleObj = {
      notifications: [
        {
          title: newMess.authorFullName,
          body: _getMessageBody(newMess),
          id: newMess.chainId,
          actionTypeId: 'NEW_CHAT_MESSAGE',
        },
      ],
    };
    await _updateBadgeCount();
    await LocalNotifications.schedule(scheduleObj);
  }

  async function cancelLocalNotifications(chainId: number): Promise<void> {
    await LocalNotifications.cancel({ notifications: [{ id: chainId }] });
    await _updateBadgeCount();
  }

  async function resetBadgeCount(): Promise<void> {
    if (!(await _isBadgeSupported())) return;
    await Badge.clear();
  }

  async function setBadgeCount(): Promise<void> {
    if (!(await _isBadgeSupported())) return;
    await resetBadgeCount();
    let permStatus = await Badge.checkPermissions();
    if (permStatus.display === 'prompt') {
      permStatus = await Badge.requestPermissions();
    }
    try {
      await _updateBadgeCount();
    } catch (error) {
      console.error('[ERROR] Failed to set unread notifications count: ', error);
      _logAndShowToast('Failed to set unread notifications count.', 'error');
    }
  }

  async function initPushNotifications(): Promise<void> {
    if (!isNativeMobile) return;

    await PushNotifications.addListener('registration', async (token) => {
      pushInitialized = true;
      await setBadgeCount();
      const deviceId = await Device.getId();
      const appInfo = await App.getInfo();
      await $api.account.register({
        pushToken: token.value,
        deviceId: deviceId.identifier,
        platform: isPlatform('android') ? 'android' : 'ios',
        packageId: appInfo.name,
        allNetworks: true,
      });
    });

    await PushNotifications.addListener('registrationError', (err) => {
      console.error('[ERROR] Registration error: ', err.error);
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    await PushNotifications.addListener('pushNotificationReceived', async (notification: PushNotificationSchema) => {
      await _updateBadgeCount();
    });

    await PushNotifications.addListener('pushNotificationActionPerformed', (action: ActionPerformed) => {
      useAppStore().$patch((state: AppState) => {
        state.fromMobilePush = true;
      });
      const data = action.notification.data as PushModel;
      //NOTE: For push notifications, pass the data.id as the entity.
      _handleNotification(Number(data.id), data.pushType, Number(data.id), true, 'push');
    });

    console.debug('[DEBUG] Push notifications initialized. Checking permissions...');
    let permStatus = await PushNotifications.checkPermissions();
    if (permStatus.receive === 'prompt') {
      console.debug('[DEBUG] Requesting permissions...');
      permStatus = await PushNotifications.requestPermissions();
    }
    if (permStatus.receive !== 'granted') {
      console.debug('[DEBUG] Permissions for push notifications denied.');
    }
    await PushNotifications.register();
  }

  async function cancelPushNotifications(): Promise<void> {
    if (isNativeMobile) {
      const deviceId = await Device.getId();
      const appInfo = await App.getInfo();
      await $api.account.unRegister({
        deviceId: deviceId.identifier,
        platform: isPlatform('android') ? 'android' : 'ios',
        packageId: appInfo.name,
        allNetworks: true,
      });
    }
  }

  /**
   * Handle notification click events.
   * For click events, the entityId is provided directly.
   * @param id The notification id.
   * @param isRead Indicates if the notification was already read.
   * @param type The notification type.
   * @param entityId The entity id to process.
   */
  async function handleNotificationClick(
    id: number,
    isRead: boolean,
    type: NotificationsPushActionsEnum,
    entityId: number
  ): Promise<void> {
    await _handleNotification(id, type, entityId, !isRead, 'click');
  }

  async function markNotificationsForChain(chainId: number): Promise<void> {
    const notificationsStore = useNotificationsStore();
    const messageNotifications = notificationsStore.data.filter(
      (n) => n.pushAction === NotificationsPushActionsEnum.MessageCreate && n.entityId === chainId && !n.isRead
    );

    if (messageNotifications.length) {
      await notificationsStore.markAs(
        messageNotifications.map((n) => n.id),
        true
      );
    }
  }
  //#endregion

  return {
    resetBadgeCount,
    setBadgeCount,
    initLocalNotifications,
    scheduleLocalNotification,
    cancelLocalNotifications,
    initPushNotifications,
    cancelPushNotifications,
    handleNotificationClick,
    markNotificationsForChain,
  };
}

export const isPushInitialized = (): boolean => pushInitialized;
